import React from "react";
import { Link } from "gatsby";
import NavLinks from "./NavLinks";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/pro-regular-svg-icons";

import logoSolid from "../img/signature-solid-navy-m1.svg";
import logoWhite from "../img/signature-multi-white.svg";

const TopNavBar = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      navBarActiveClass: "hidden",
    };
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: "block",
            })
          : this.setState({
              navBarActiveClass: "hidden",
            });
      }
    );
  };

  render() {
    const { navBarActiveClass, active } = this.state;
    const logoSrc = this.props.logo || logoSolid;

    return (
      <div
        className={`flex items-stretch justify-between text-accent-reverse relative z-40`}
      >
        <Link to="/" className="p-5 flex items-center flex-none">
          <img src={logoSrc} width="166" alt="ScriptDrop" />
        </Link>

        <div
          className={`${navBarActiveClass} lg:hidden fixed w-full h-full bg-accent-p2 opacity-75`}
          onClick={() => this.toggleHamburger()}
        >
          &nbsp;
        </div>

        <div
          className={`${navBarActiveClass}
            text-white lg:text-accent-reverse
            top-0 right-0
            
            h-full sm:h-auto
            
            sm:rounded-bl-lg

            bg-navy lg:bg-transparent
            shadow-xl lg:shadow-none
            fixed lg:relative
            lg:flex
            w-full sm:w-auto lg:w-full
            pb-8 lg:pb-0
            lg:justify-between`}
        >
          <nav role="navigation" aria-label="main-navigation">
            <Link to="/" className="sm:hidden p-5 flex items-center flex-none">
              <img src={logoWhite} width="166" alt="ScriptDrop" />
            </Link>

            <NavLinks
              className="pb-6 px-8 lg:px-0 lg:pb-0 pt-6 sm:pt-16 lg:pt-0 md:h-full"
              itemClassname="text-white lg:text-accent-reverse hover:text-accent-reverse-hover"
            />
          </nav>

          <nav
            role="navigation"
            className="flex px-8 lg:px-0 my-4 lg:my-0 items-end justify-end content-end"
            aria-label="featured-navigation"
          >
            <a
              className="
              self-center
              py-2 px-8
              
              min-w-full
              
              sm:min-w-1/2
              md:min-w-1/3
              
              rounded-full
              lg:rounded-tr-none lg:rounded-br-none

              bg-royal

              lg:pr-4
              lg:pl-5

              duration-300
              lg:transform
              lg:transition-spacing
              lg:bg-accent-callout
              lg:hover:pl-6
              lg:active:scale-99
              
              text-white
              font-semibold
              uppercase
              tracking-wider
              
              text-center
            "
              href="/contact"
            >
              Contact&nbsp;Us
            </a>
          </nav>
        </div>

        <button
          title="show menu"
          onClick={() => this.toggleHamburger()}
          style={{ zIndex: 32 }}
          className={`${
            active ? "" : "text-navy-m1 bg-accent-callout"
          } text-white lg:bg-transparent rounded-bl-xl fixed lg:absolute top-0 right-0 w-16 lg:hidden p-4 active:bg-shadow-p1 tap-highlight-none transition-background duration-200`}
        >
          <FontAwesomeIcon icon={active ? faTimes : faBars} size="lg" />
        </button>
      </div>
    );
  }
};

export default TopNavBar;
