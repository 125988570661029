import React from "react";
import { Link } from "gatsby";

import NavLinks from "./NavLinks";

import logo from "../img/signature-solid-navy.svg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter,
  faFacebook,
  faLinkedin,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";

const FooterLink = ({ to, href, children, title }) => (
  <Link to={href} title={title} className="py-1 block hover:text-royal">
    {children}
  </Link>
);

const Footer = class extends React.Component {
  render() {
    return (
      <>
        <div className="p-4 bg-white">&nbsp;</div>
        <footer className="pt-10 sm:rounded-none bg-navy-p3 sm:bg-white sm:bg-curve-tr-d-sm-navy-p3 md:bg-curve-tr-d-navy-p3 lg:bg-curve-tr-d-sm-navy-p3 bg-stretch">
          <div className="container">
            <div className="columns">
              <div className="w-full lg:w-3/12">
                <h3 className="font-display text-2xl leading-tight font-bold">
                  Interested in a
                  <br />
                  ScriptDrop Career?
                </h3>
                <Link
                  to="/team"
                  className="btn btn-royal-o btn-avenir text-sm mt-3 lg:w-3/4"
                >
                  Join Our Team
                </Link>
              </div>

              <div className="w-full text-xs font-medium uppercase sm:w-1/2 md:w-1/3 lg:w-2/12">
                <h3 className="font-semibold text-sm tracking-wide mb-1">
                  Resources
                </h3>
                <ul>
                  <li>
                    <FooterLink href="/news">News</FooterLink>
                  </li>
                  <li>
                    <FooterLink href="/faq">FAQ</FooterLink>
                  </li>
                  <li>
                    <FooterLink href="https://recruiting.paylocity.com/recruiting/jobs/All/32ce305a-6464-4d04-8a69-f5e0c909e625/ScriptDrop-Inc">Careers</FooterLink>
                  </li>
                  <li>
                    <FooterLink href="/terms-of-use">
                      Terms + Privacy
                    </FooterLink>
                  </li>
                  <li>
                    <FooterLink href="https://scriptdrop.statuspage.io/">
                      Status
                    </FooterLink>
                  </li>
                  <li>
                    <FooterLink href="/login">Portal Login</FooterLink>
                  </li>
                  <li>
                    <FooterLink href="/support">Support</FooterLink>
                  </li>
                </ul>
              </div>

              <div className="w-full text-xs font-medium uppercase sm:w-1/2 md:w-1/3 lg:w-3/12">
                <h3 className="font-semibold text-sm tracking-wide mb-1">
                  Support Hours
                </h3>
                <p className="py-1">Weekdays  12 PM to 8 PM ET</p>
                <p className="py-1">Closed Weekends</p>
                <p className="py-1">Closed Holidays</p>
              </div>

              <div className="w-full text-xs font-medium uppercase sm:w-1/2 md:w-1/3 lg:w-2/12">
                <h3 className="font-semibold text-sm tracking-wide mb-1">
                  Location
                </h3>
                <address className="not-italic">
                  <span className="py-1 block">1985 W Henderson Rd</span>
                  <span className="py-1 block">PMB 3066</span>
                  <span className="py-1 block">Columbus, OH 43220</span>
                </address>

                <h3 className="mt-6 font-semibold text-sm tracking-wide mb-1">
                  Contact Us
                </h3>
                <span className="py-1 block">
                  <a href="tel:+18003767715">(800)&nbsp;376-7715</a>
                </span>
                <span className="py-1 block">
                  <a href="mailto:info@scriptdrop.co">info@scriptdrop.co</a>
                </span>
              </div>
            </div>
          </div>

          <div className="px-5 pt-10 pb-4 flex justify-between">
            <a href="/" className="pr-5 md:pl-1 pb-1 flex items-center">
              <img src={logo} width="166" alt="ScriptDrop" />
            </a>
            <nav
              className="lg:block hidden flex-grow"
              role="navigation"
              aria-label="footer-navigation"
            >
              <NavLinks itemClassname="text-navy hover:text-royal" />
            </nav>
            <div className="pr-5 flex items-center space-x-3 justify-end">
              <FooterLink
                title="LinkedIn"
                href="https://www.linkedin.com/company/scriptdrop/"
              >
                <FontAwesomeIcon icon={faLinkedin} size="lg" />
              </FooterLink>
              <FooterLink title="Twitter" href="https://twitter.com/scriptdrop">
                <FontAwesomeIcon icon={faTwitter} size="lg" />
              </FooterLink>
              <FooterLink
                title="Facebook"
                href="https://facebook.com/scriptdrop"
              >
                <FontAwesomeIcon icon={faFacebook} size="lg" />
              </FooterLink>
              <FooterLink
                title="Instagram"
                href="https://instagram.com/scriptdropdelivers"
              >
                <FontAwesomeIcon icon={faInstagram} size="lg" />
              </FooterLink>
            </div>
          </div>
        </footer>
      </>
    );
  }
};

export default Footer;
