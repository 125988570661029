import React from "react";
import TopNavBar from "./TopNavBar";
import { Parallax } from "react-scroll-parallax";

import dotsVertical from "../img/decorative/dots-white-vertical.svg";
import crossHatch from "../img/decorative/crosshatch-medium.svg";

const Header = (props) => {
  const { className, title, photo, logo, children } = props;

  return (
    <header className={`${className}`}>
      <TopNavBar logo={logo} />

      {children || (
        <>
          <div className="hidden xl:block absolute right-0 top-0 w-3/12 md:pt-0 sm:w-3/12 md:w-2/12 mr-4 md:mr-24 lg:mr-48 xl:mr-32 z-30">
            <Parallax y={[-100, 10]} tagOuter="div">
              <img className="" width="11" alt="" src={dotsVertical} />
            </Parallax>
          </div>

          <div className="hidden lg:block absolute right-0 top-0 w-3/12 md:pt-0 sm:w-3/12 md:w-2/12 mr-16 md:mr-32 lg:mr-64 xl:mr-48 z-20">
            <Parallax y={[-125, -22]} tagOuter="div">
              <div className="block bg-white lg:opacity-25 rounded-full w-32 h-32 md:w-40 md:h-40 lg:w-48 lg:h-48"></div>
            </Parallax>
          </div>

          <div className="container relative">
            <div className="absolute overflow-y-hidden right-0 bottom-0 pt-4 md:pt-0 mr-6 sm:mr-8 md:mr-16 lg:mr-48 xl:mr-24 z-30">
              <Parallax y={[0, 0]} tagOuter="div" className="overflow-hidden">
                {typeof photo == "string" ? (
                  <img
                    className="h-32 lg:h-48 xl:h-56 w-auto"
                    alt=""
                    src={photo}
                  />
                ) : (
                  <div className="h-32 md:h-48 xl:h-56 w-32 sm:w-48 md:w-56 xl:w-64 flex flex-col justify-end">
                    {photo}
                  </div>
                )}
              </Parallax>
            </div>

            <div className="absolute overflow-y-hidden opacity-50 right-0 bottom-0 w-3/12 pl-4 lg:w-3/12 pt-4 md:pt-0 sm:w-3/12 md:w-2/12 mr-16 md:mr-32 lg:mr-48 xl:mr-32 z-10">
              <Parallax y={[30, 70]} tagOuter="div" className="overflow-hidden">
                <img
                  width="150"
                  alt=""
                  className="transform rotate-90"
                  src={crossHatch}
                />
              </Parallax>
            </div>

            <div className="z-30 relative">
              {typeof title == "string" ? (
                <div className="columns md:pb-4 lg:pb-10 lg:pt-4">
                  <div className="w-full lg:w-10/12">
                    <h1 className="font-display font-bold text-white text-3xl lg:text-5xl">
                      {title}
                    </h1>
                  </div>
                </div>
              ) : (
                title
              )}
            </div>
          </div>
        </>
      )}
    </header>
  );
};

export default Header;
