import React from "react";
import { Link } from "gatsby";

const items = [
  {
    to: "/providers",
    content: "Providers",
  },
  {
    to: "/pharmacies",
    content: "Pharmacies",
  },
  {
    to: "/patients",
    content: "Patients",
  },
  {
    to: "/aboutus",
    content: "About Us",
  },
  {
    to: "/thebeat",
    content: "The Beat",
  },
  {
    to: "/rx-capture",
    content: "RxCapture",
  }
];

export default ({ itemClassname, className }) => {
  const classNames = `px-4 py-3 transition-colors duration-100 text-md xl:text-base flex-grow-1 lg:px-3 lg:py-5 ${itemClassname}`;

  return (
    <div
      className={`
      clear-both
      font-semibold uppercase
      flex
      flex-col lg:flex-row
      items-center
      sm:items-end lg:items-center
      ${className}
      `}
    >
      {items.map((item) => {
        return (
          <Link key={`navbar-${item.to}`} className={classNames} to={item.to}>
            {item.content}
          </Link>
        );
      })}
    </div>
  );
};
