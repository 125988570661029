import React from "react";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { ParallaxProvider } from "react-scroll-parallax";
import "./all.css";
import useSiteMetadata from "./SiteMetadata";
import { withPrefix } from "gatsby";
import defaultShareImage from "../img/social-share-default.png";

import { library } from "@fortawesome/fontawesome-svg-core";
import { far } from "@fortawesome/pro-regular-svg-icons";

library.add(far);

const TemplateWrapper = ({
  children,
  navbarLogo,
  headerTitle,
  headerPhoto,
  headerContent, // If present, overrides headerTitle and headerPhoto
  shareImage,
  className,
  headerClassName = "curve-br",
}) => {
  const { title, description, siteUrl } = useSiteMetadata();
  const socialShareImage = shareImage || defaultShareImage;

  return (
    <ParallaxProvider>
      <div className={`text-navy font-body flex h-full flex-col ${className}`}>
        <Helmet>
          <html lang="en" className={`${className} bg-accent`} />
          <title>{title}</title>
          <meta name="description" content={description} />

          <link
            rel="icon"
            type="image/png"
            href={`${withPrefix("/")}img/favicon-32x32.png`}
            sizes="32x32"
          />
          <link
            rel="icon"
            type="image/png"
            href={`${withPrefix("/")}img/favicon-32x32.png`}
            sizes="64x64"
          />
          <link
            rel="icon"
            type="image/png"
            href={`${withPrefix("/")}img/favicon-16x16.png`}
            sizes="16x16"
          />

          <link
            rel="icon"
            type="image/svg"
            href={`${withPrefix("/")}img/favicon.svg`}
            sizes="16x16"
          />

          <meta name="theme-color" content="#003052" />

          <meta property="og:type" content="business.business" />
          <meta property="og:title" content={title} />
          <meta property="og:url" content="/" />

          <meta property="og:site_name" content="ScriptDrop" />
          <meta
            property="article:publisher"
            content="https://www.facebook.com/scriptdrop/"
          />

          <meta
            property="og:image"
            content={siteUrl.concat(`${socialShareImage}`)}
          />

          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:creator" content="@ScriptDrop" />
          <meta name="twitter:site" content="@ScriptDrop" />

          <link
            rel="stylesheet"
            id="grange-css"
            href="https://use.typekit.net/efg5qgr.css"
            type="text/css"
            media="all"
          />
        </Helmet>

        {/* TODO: Dynamic based on page */}

        <div className="flex-auto flex-shrink-0 bg-white">
          <Header
            title={headerTitle}
            photo={headerPhoto}
            logo={navbarLogo}
            className={`flex-shrink-0 ${headerClassName}`}
          >
            {headerContent}
          </Header>

          <main>{children}</main>
        </div>

        <Footer className="flex-shrink-0" />
      </div>
    </ParallaxProvider>
  );
};

export default TemplateWrapper;
